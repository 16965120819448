var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in"},[(_vm.loading)?_c('Loading'):_vm._e(),_c('a-row',{attrs:{"type":"flex","gutter":[24, 24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form",attrs:{"span":24,"md":12,"lg":{ span: 12, offset: 0 },"xl":{ span: 6, offset: 0 }}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Username","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'username',
              {
                rules: [
                  { required: true, message: 'Please input your username!' } ],
              } ]),expression:"[\n              'username',\n              {\n                rules: [\n                  { required: true, message: 'Please input your username!' },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Username"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [
                  { required: true, message: 'Please input your password!' } ],
              } ]),expression:"[\n              'password',\n              {\n                rules: [\n                  { required: true, message: 'Please input your password!' },\n                ],\n              },\n            ]"}],attrs:{"type":"password","placeholder":"Password"}})],1),(_vm.error)?_c('p',{staticClass:"text-center text-danger"},[_vm._v(_vm._s(_vm.errorMsg))]):_vm._e(),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"block":"","html-type":"submit","type":"primary"}},[_vm._v(" เข้าสู่ระบบ ")])],1)],1),_c('a-button',{staticClass:"register-button mb-10",attrs:{"block":"","href":_vm.registerHref,"target":"_blank"}},[_vm._v(" สมัครสมาชิก")]),_c('a-button',{staticClass:"header_button_size mr-10",staticStyle:{"background-color":"#00b900"},attrs:{"block":"","href":_vm.lineHref,"target":"_blank"}},[_c('img',{staticClass:"header_line",attrs:{"src":"images/line.png","alt":"line"}}),_c('span',{staticStyle:{"color":"white"}},[_vm._v(" ติดต่อสอบถาม ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }